import React from 'react'

const CheckMarkIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' fill='#5c4bce' viewBox='0 0 72 72' width='25px' height='25px'>
            <path d='M57.658,12.643c1.854,1.201,2.384,3.678,1.183,5.532l-25.915,40c-0.682,1.051-1.815,1.723-3.064,1.814 C29.764,59.997,29.665,60,29.568,60c-1.146,0-2.241-0.491-3.003-1.358L13.514,43.807c-1.459-1.659-1.298-4.186,0.36-5.646 c1.662-1.46,4.188-1.296,5.646,0.361l9.563,10.87l23.043-35.567C53.329,11.971,55.806,11.442,57.658,12.643z' />
        </svg>
    )
}

export default CheckMarkIcon
