import React from 'react'

const UploadIcon = (props) => {
    return (
        <svg
            width='25px'
            height='25px'
            viewBox='0 0 64 64'
            xmlns='http://www.w3.org/2000/svg'
            strokeWidth='3'
            stroke='#5c4bce'
            fill='none'
            {...props}
        >
            <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
            <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
            <g id='SVGRepo_iconCarrier'>
                <polyline points='32 12 32 44'></polyline>
                <polyline points='20 26 32 12 44 26'></polyline>
                <rect x='12' y='44' width='40' height='8' rx='2'></rect>
            </g>
        </svg>
    )
}

export default UploadIcon
