import React, { createContext, useContext } from 'react'

// Создаем контекст для сообщений
const ConfigContext = createContext()

// Экспортируем хук для использования контекста
export const useConfig = () => {
    return useContext(ConfigContext)
}

// Компонент провайдер для сообщений
export const ConfigProvider = ({ config, children }) => {
    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}
