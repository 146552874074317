import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const configAPI = createApi({
    reducerPath: 'configAPI',
    baseQuery: fetchBaseQuery({
        // eslint-disable-next-line no-undef
        baseUrl: `${process.env.REACT_APP_API_URL}/api/private/v1/`
    }),
    tagTypes: ['Get'],
    endpoints: (build) => ({
        getConfig: build.mutation({
            query: () => ({
                url: `/config/`
            })
        })
    })
})

export const { useGetConfigMutation } = configAPI
