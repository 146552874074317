import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import { MessagesProvider } from './providers/MessagesProvider'
import { ConfigProvider } from './providers/PrivateConfigProvider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useGetConfigMutation } from './redux'
import { useSelector } from 'react-redux'

import Example from './routes/Example'
import ReportByUUID from './routes/ReportByUUID'
import PromptingTest from './routes/PromptingTest'

function App({ messages }) {
    const isLoading = useSelector((state) => state.loading.isLoading)

    const [config, setConfig] = useState({})

    const [fetchConfigRequest] = useGetConfigMutation()

    const fetchConfig = async () => {
        const response = await fetchConfigRequest()
        setConfig(response.data)
    }

    useEffect(() => {
        fetchConfig()
    }, [])

    useEffect(() => {
        document.body.style.overflow = isLoading ? 'hidden' : 'scroll'
    }, [isLoading])

    return (
        <MessagesProvider messages={messages}>
            <ToastContainer />
            <ConfigProvider config={config}>
                {isLoading && <div className={'loader'} />}
                <div className='backdrop' style={{ opacity: isLoading ? 0.5 : 1, pointerEvents: isLoading ? 'none' : '' }}>
                    <Routes>
                        <Route path={'/app/example'} element={<Example />} messages={messages} />
                        <Route path={'/app/report/:report_uuid'} element={<ReportByUUID />} />
                        {config?.prompting_test_page && <Route path={'/app/prompt-test'} element={<PromptingTest />} />}
                    </Routes>
                </div>
            </ConfigProvider>
        </MessagesProvider>
    )
}

export default App
