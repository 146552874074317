import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const reportAPI = createApi({
    reducerPath: 'reportAPI',
    baseQuery: fetchBaseQuery({
        // eslint-disable-next-line no-undef
        baseUrl: `${process.env.REACT_APP_API_URL}/api/public/v1/`
    }),
    tagTypes: ['Get', 'Post'],
    endpoints: (build) => ({
        getReport: build.mutation({
            query: (report_id) => ({
                url: `/report/${report_id}/`
            })
        }),
        createReport: build.mutation({
            query: (payload) => ({
                url: '/report/',
                method: 'POST',
                body: payload,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }),
            invalidatesTags: ['Post']
        }),
        getExampleReport: build.mutation({
            query: () => ({
                url: '/report/example/'
            })
        }),
        uploadPDFReport: build.mutation({
            query: (payload) => ({
                url: '/upload_pdf/',
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['Post']
        }),
        taskStatus: build.mutation({
            query: (task_id) => ({
                url: `/task/status/${task_id}/`
            })
        })
    })
})

export const {
    useGetReportMutation,
    useCreateReportMutation,
    useGetExampleReportMutation,
    useUploadPDFReportMutation,
    useTaskStatusMutation
} = reportAPI
