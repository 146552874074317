import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const promptingTestAPI = createApi({
    reducerPath: 'promptingTestAPI',
    baseQuery: fetchBaseQuery({
        // eslint-disable-next-line no-undef
        baseUrl: `${process.env.REACT_APP_API_URL}/api/private/v1/`,
        prepareHeaders: (headers) => {
            headers.append('Authorization', sessionStorage.getItem('authHeader'))
            return headers
        }
    }),
    tagTypes: ['Get', 'Post'],
    endpoints: (build) => ({
        getTestReports: build.mutation({
            query: () => ({
                url: '/test_reports/'
            })
        }),
        getConfigPrompts: build.mutation({
            query: () => ({
                url: '/config_prompts/'
            })
        }),
        postTestPrompts: build.mutation({
            query: (data) => ({
                url: '/test_reports/prompts/',
                method: 'POST',
                body: data
            })
        })
    })
})

export const { useGetTestReportsMutation, useGetConfigPromptsMutation, usePostTestPromptsMutation } = promptingTestAPI
