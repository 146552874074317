import React, { useEffect, useState } from 'react'
import './index.css'
import CloseIcon from '../../../icons/CloseIcon'

const TermModal = ({ activeTerm, position, isMobile, onClose }) => {
    const [isVisible, setIsVisible] = useState(false)
    const [currentTerm, setCurrentTerm] = useState(activeTerm)
    const [imageVisible, setImageVisible] = useState(false)

    useEffect(() => {
        if (!isVisible) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
            setTimeout(() => {
                setCurrentTerm(activeTerm)
                setIsVisible(true)
            }, 300)
        }
    }, [activeTerm])

    return (
        <div
            className='term-modal-wrapper'
            style={
                isMobile
                    ? {
                          top: position.top,
                          position: 'absolute',
                          display: 'block',
                          minWidth: '100%',
                          opacity: 1
                      }
                    : {}
            }
        >
            <div className='term-modal-content'>
                <div className='x-panel' onClick={onClose}>
                    <CloseIcon stroke={'#8a7bea'} />
                </div>
                <div className={`term-content ${isVisible ? 'visible' : ''}`}>
                    <h2 className='term-title'>{currentTerm?.term}</h2>
                    <div className='term-description'>
                        <p>{currentTerm?.description}</p>
                    </div>
                    <div className={`term-image ${imageVisible && 'visible'}`}>
                        <img src={activeTerm?.link} alt={`${activeTerm?.term}-image`} onLoad={() => setImageVisible(true)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermModal
