import React from 'react'
import { toast } from 'react-toastify'
import messages from '../../messages'

// eslint-disable-next-line no-undef
const localeMessages = messages[process.env.REACT_APP_LANGUAGE || 'ru']

export const errorAlert = (title, message) => {
    toast.error(
        <>
            <p>{title ? title : localeMessages['oops']}</p>
            <p>{message ? message : localeMessages['something_wrong']}</p>
        </>
    )
}
