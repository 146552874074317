import { configureStore } from '@reduxjs/toolkit'
import { reportAPI, configAPI, promptingTestAPI } from './api'
import loadingReducer from './api/loadingSlice'

export const store = configureStore({
    reducer: {
        [reportAPI.reducerPath]: reportAPI.reducer,
        [configAPI.reducerPath]: configAPI.reducer,
        [promptingTestAPI.reducerPath]: promptingTestAPI.reducer,
        loading: loadingReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(reportAPI.middleware, configAPI.middleware, promptingTestAPI.middleware)
})
