import React from 'react'

const CursorAnimationSvg = () => {
    return (
        <svg className='cursor-animation' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 70'>
            <circle className='t_cursor-highlight t-c_cls-1' cx='23' cy='23' r='18'></circle>
            <g className='t_cursor-container-group'>
                <g className='t_cursor-group'>
                    <path
                        className='t_cursor-shadow t-c_cls-3'
                        d='m36.03,63.78l3.05-10.66c.48-1.69.73-3.43.73-5.18v-4.9c0-2.08-1.69-3.77-3.77-3.77h-1.89c0-1.04-.84-1.89-1.89-1.89h-3.77c0-1.04-.84-1.89-1.89-1.89h-3.77s0-8.49,0-8.49c0-1.56-1.27-2.83-2.83-2.83s-2.83,1.27-2.83,2.83v21.69s-5.01-4.29-5.01-4.29c-.87-.73-2.14-.7-2.98.06s-.98,2.03-.34,2.96c0,0,7.39,12.59,10.22,16.36h16.97Z'
                    ></path>
                    <g className='t_cursor'>
                        <path
                            className='t-c_cls-2'
                            d='m39.03,59.78l3.05-10.66c.48-1.69.73-3.43.73-5.18v-4.9c0-2.08-1.69-3.77-3.77-3.77h-1.89c0-1.04-.84-1.89-1.89-1.89h-3.77c0-1.04-.84-1.89-1.89-1.89h-3.77s0-8.49,0-8.49c0-1.56-1.27-2.83-2.83-2.83s-2.83,1.27-2.83,2.83v21.69s-5.01-4.29-5.01-4.29c-.87-.73-2.14-.7-2.98.06s-.98,2.03-.34,2.96c0,0,7.39,12.59,10.22,16.36h16.97Z'
                        ></path>
                        <path className='t-c_cls-4' d='m31.49,33.37v3.77'></path>
                        <path className='t-c_cls-4' d='m25.83,31.49v5.66'></path>
                        <path className='t-c_cls-4' d='m37.14,35.26v3.77'></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CursorAnimationSvg
