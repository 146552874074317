import React from 'react'

const TutorialSvg = () => {
    return (
        <svg className='tutorial-animation desktop post-animation' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 190 190'>
            <defs>
                <clipPath id='t_desktop-screen-clip'>
                    <rect className='t-d_cls-3' x='18.5' y='38.5' width='153' height='113' rx='7' ry='7'></rect>
                </clipPath>
            </defs>
            <g className='t_accent-circle'>
                <circle className='t-d_cls-16' cx='95' cy='95' r='89'></circle>
            </g>
            <g className='t_device'>
                <g className='t_device-shadow t-d_cls-20'>
                    <rect className='t-d_cls-14' x='10.5' y='38.5' width='161' height='121' rx='11' ry='11'></rect>
                </g>
                <g className='t_device-body'>
                    <rect className='t-d_cls-14' x='14.5' y='34.5' width='161' height='121' rx='11' ry='11'></rect>
                </g>
                <g className='t_screen-content'>
                    <g className='t-d_cls-10'>
                        <g className='t_screen-background'>
                            <rect className='t-d_cls-18' x='18' y='38' width='154' height='114'></rect>
                        </g>
                        <g className='t_report-page'>
                            <rect className='t-d_cls-17' x='25.5' y='45.5' width='77' height='114' rx='3.5' ry='3.5'></rect>
                            <circle className='t_exam-type-icon t-d_cls-19' cx='33' cy='53' r='3' data-diagram-type='abdom'></circle>
                            <line className='t-d_cls-2' x1='41' y1='53' x2='73' y2='53'></line>
                            <line className='t-d_cls-4' x1='30.5' y1='61' x2='97.5' y2='61'></line>
                            <line className='t-d_cls-6' x1='31' y1='69.5' x2='97' y2='69.5'></line>
                            <line className='t-d_cls-6' x1='31' y1='74.5' x2='90.05' y2='74.5'></line>
                            <line className='t-d_cls-6' x1='31' y1='79.5' x2='97' y2='79.5'></line>
                            <line className='t-d_cls-6' x1='31' y1='84.5' x2='76.16' y2='84.5'></line>
                            <line className='t-d_cls-6' x1='31' y1='95.5' x2='97' y2='95.5'></line>
                            <line className='t-d_cls-6' x1='31' y1='100.5' x2='90.05' y2='100.5'></line>
                            <line className='t-d_cls-6' x1='31' y1='105.5' x2='76.16' y2='105.5'></line>
                            <line className='t-d_cls-6' x1='31' y1='132.5' x2='97' y2='132.5'></line>
                            <line className='t-d_cls-6' x1='31' y1='137.5' x2='97' y2='137.5'></line>
                            <line className='t-d_cls-6' x1='31' y1='142.5' x2='76.16' y2='142.5'></line>
                            <line className='t-d_cls-6' x1='31' y1='116.5' x2='97' y2='116.5'></line>
                            <line className='t-d_cls-6' x1='31' y1='121.5' x2='76.16' y2='121.5'></line>
                            <g className='t_highlights'>
                                <rect className='r1 t-d_cls-12' x='34' y='67.5' width='34' height='4'></rect>
                                <rect className='r2 t-d_cls-11' x='77' y='67.5' width='17' height='4'></rect>
                                <rect className='r3 t-d_cls-11' x='34' y='77.5' width='17' height='4'></rect>
                                <rect className='r4 t-d_cls-12' x='68' y='77.5' width='26' height='4'></rect>
                                <rect className='r5 t-d_cls-12' x='34' y='82.5' width='26' height='4'></rect>
                                <rect className='r6 t-d_cls-11' x='44' y='93.5' width='26' height='4'></rect>
                                <rect className='r7 t-d_cls-12' x='58' y='98.5' width='26' height='4'></rect>
                                <rect className='r8 t-d_cls-12' x='34' y='103.5' width='17' height='4'></rect>
                                <rect className='r9 t-d_cls-11' x='34' y='114.5' width='34' height='4'></rect>
                                <rect className='r10 t-d_cls-12' x='77' y='114.5' width='17' height='4'></rect>
                                <rect className='r11 t-d_cls-12' x='34' y='130.5' width='26' height='4'></rect>
                                <rect className='r12 t-d_cls-12' x='67' y='130.5' width='17' height='4'></rect>
                                <rect className='r13 t-d_cls-11' x='63' y='135.5' width='26' height='4'></rect>
                                <rect className='r14 t-d_cls-12' x='38' y='140.5' width='17' height='4'></rect>
                            </g>
                        </g>
                    </g>
                </g>
                <g className='t_def-panel'>
                    <g className='t_def-panel-body'>
                        <rect className='t-d_cls-15' x='107.5' y='78' width='57' height='34' rx='5' ry='5'></rect>
                        <line className='t-d_cls-1' x1='114.5' y1='86' x2='140.5' y2='86'></line>
                        <line className='t-d_cls-5' x1='114' y1='94.5' x2='158' y2='94.5'></line>
                        <line className='t-d_cls-5' x1='114' y1='99.5' x2='158' y2='99.5'></line>
                        <line className='t-d_cls-5' x1='114' y1='104.5' x2='144' y2='104.5'></line>
                    </g>
                </g>
            </g>
            <circle className='t_cursor-highlight t-d_cls-13' cx='81' cy='79.5' r='12'></circle>
            <g className='t_cursor-container-group'>
                <g className='t_cursor-group'>
                    <path
                        className='t_cursor-shadow t-d_cls-9'
                        d='m108.03,135.78l3.05-10.66c.48-1.69.73-3.43.73-5.18v-4.9c0-2.08-1.69-3.77-3.77-3.77h-1.89c0-1.04-.84-1.89-1.89-1.89h-3.77c0-1.04-.84-1.89-1.89-1.89h-3.77s0-8.49,0-8.49c0-1.56-1.27-2.83-2.83-2.83s-2.83,1.27-2.83,2.83v21.69s-5.01-4.29-5.01-4.29c-.87-.73-2.14-.7-2.98.06s-.98,2.03-.34,2.96c0,0,7.39,12.59,10.22,16.36h16.97Z'
                    ></path>
                    <g className='t_cursor'>
                        <path
                            className='t-d_cls-8'
                            d='m111.03,131.78l3.05-10.66c.48-1.69.73-3.43.73-5.18v-4.9c0-2.08-1.69-3.77-3.77-3.77h-1.89c0-1.04-.84-1.89-1.89-1.89h-3.77c0-1.04-.84-1.89-1.89-1.89h-3.77s0-8.49,0-8.49c0-1.56-1.27-2.83-2.83-2.83s-2.83,1.27-2.83,2.83v21.69s-5.01-4.29-5.01-4.29c-.87-.73-2.14-.7-2.98.06s-.98,2.03-.34,2.96c0,0,7.39,12.59,10.22,16.36h16.97Z'
                        ></path>
                        <path className='t-d_cls-7' d='m103.49,105.37v3.77'></path>
                        <path className='t-d_cls-7' d='m97.83,103.49v5.66'></path>
                        <path className='t-d_cls-7' d='m109.14,107.26v3.77'></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default TutorialSvg
