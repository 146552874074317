import React, { useState } from 'react'
import { toast } from 'react-toastify'
import './index.css'
import { FormattedMessage } from 'react-intl'

const Index = ({ visible, onOk }) => {
    const [state, setState] = useState({
        username: '',
        password: ''
    })

    const onChangeInput = (e) => setState({ ...state, [e.target.name]: e.target.value })

    const onOkHandler = () => {
        const { username, password } = state

        if (!username || !password) {
            toast.error('Please enter both username and password')
        } else {
            sessionStorage.setItem('authHeader', 'Basic ' + btoa(`${username}:${password}`))
            onOk()
        }
    }

    return (
        <div className={'modal_container'} style={{ display: visible ? 'flex' : 'none' }}>
            <div className={'modal'}>
                <input type='text' name={'username'} placeholder={'Username'} onChange={(e) => onChangeInput(e)} />
                <input type='password' name={'password'} placeholder={'Password'} onChange={(e) => onChangeInput(e)} />

                <div className={'modal_actions'}>
                    <button onClick={onOkHandler}>
                        <FormattedMessage id={'login'} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Index
