import React from 'react'

const CloseIcon = (props) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' id='Layer_1' data-name='Layer 1' viewBox='0 0 20 20'>
            <rect className='cls-2' width='25' height='25'></rect>
            <g>
                <line
                    stroke='#5c4bce'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    x1='6.26'
                    y1='6.26'
                    x2='13.74'
                    y2='13.74'
                    {...props}
                ></line>
                <line
                    stroke='#5c4bce'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    x1='13.74'
                    y1='6.26'
                    x2='6.26'
                    y2='13.74'
                    {...props}
                ></line>
            </g>
        </svg>
    )
}

export default CloseIcon
