import React, { useEffect, useState } from 'react'
import { useGetReportMutation } from '../../redux'
import Report from '../../components/Highlighting/Report'
import { useParams } from 'react-router-dom'

const Index = ({ messages }) => {
    const { report_uuid } = useParams()

    const [report, setReport] = useState(null)

    const [fetchReportRequest] = useGetReportMutation()

    const fetchReportByUUID = async () => {
        const response = await fetchReportRequest(report_uuid)
        setReport(response.data)
    }

    useEffect(() => {
        fetchReportByUUID()
    }, [])

    return <div className='container'>{report && <Report report={report} messages={messages} />}</div>
}

export default Index
