import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './redux'
import messages from './messages'

// eslint-disable-next-line no-undef
const defaultLanguage = process.env.REACT_APP_LANGUAGE || 'ru'
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <IntlProvider locale={defaultLanguage} messages={messages[defaultLanguage]} defaultLocale={'ru'}>
                <App messages={messages[defaultLanguage]} />
            </IntlProvider>
        </BrowserRouter>
    </Provider>
)

reportWebVitals()
