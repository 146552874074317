import React, { createContext, useContext } from 'react'

// Создаем контекст для сообщений
const MessagesContext = createContext()

// Экспортируем хук для использования контекста
export const useMessages = () => {
    return useContext(MessagesContext)
}

// Компонент провайдер для сообщений
export const MessagesProvider = ({ messages, children }) => {
    return <MessagesContext.Provider value={messages}>{children}</MessagesContext.Provider>
}
