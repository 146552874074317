import React, { useEffect, useState } from 'react'
import './index.css'
import {
    useGetTestReportsMutation,
    useGetConfigPromptsMutation,
    usePostTestPromptsMutation,
    setLoadingTrue,
    setLoadingFalse
} from '../../redux'
import Report from '../../components/Highlighting/Report'
import LoginModal from '../../components/popups/login'
import { FormattedMessage } from 'react-intl'
import { errorAlert } from '../../components/popups/allerts'
import { useDispatch } from 'react-redux'

const Index = () => {
    const dispatch = useDispatch()

    // Selectors, Queries, Mutations
    const [getTestReportsRequest] = useGetTestReportsMutation()
    const [getConfigPromptsRequest] = useGetConfigPromptsMutation()
    const [postTestPromptsRequest] = usePostTestPromptsMutation()

    // Local States
    const [showPage, setShowPage] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const [error, setError] = useState(null)
    const [testReports, setTestReports] = useState([])
    const [prompts, setPrompts] = useState({
        system_prompt_stage_1: '',
        prompt_stage_1: '',
        system_prompt_stage_2: '',
        prompt_stage_2: ''
    })

    // Event Listeners, Functions
    const onChangePrompt = (e) => setPrompts({ ...prompts, [e.target.name]: e.target.value })

    const onSend = async () => {
        try {
            dispatch(setLoadingTrue())
            const response = await postTestPromptsRequest(prompts)
            if (response.error) throw response.error
            setTestReports(response.data)
            dispatch(setLoadingFalse())
        } catch (e) {
            setError(e)
            dispatch(setLoadingFalse())
        }
    }

    const fetchTestReports = async () => {
        try {
            dispatch(setLoadingTrue())
            const response = await getTestReportsRequest()
            if (response.error) throw response.error
            setTestReports(response.data)
            dispatch(setLoadingFalse())
            setShowPage(true)
        } catch (e) {
            setError(e)
            dispatch(setLoadingFalse())
        }
    }

    const fetchConfigPrompts = async () => {
        try {
            const response = await getConfigPromptsRequest()
            if (response.error) throw response.error
            setPrompts(response.data)
            return response.data
        } catch (e) {
            setError(e)
        }
    }

    const checkAuth = async () => {
        if (!sessionStorage.getItem('authHeader')) {
            setShowModal(true)
        } else {
            fetchConfigPrompts().then(async (data) => {
                if (data) {
                    await fetchTestReports()
                    setShowModal(false)
                    setShowPage(true)
                }
            })
        }
    }

    useEffect(() => {
        checkAuth()
    }, [])

    useEffect(() => {
        if (error) {
            if (error.status === 401) {
                sessionStorage.removeItem('authHeader')
            }
            errorAlert(error.status, error.data.detail)
            setShowPage(false)
            setShowModal(true)
        }
    }, [error])

    return (
        <div className={`prompting_container container`}>
            <LoginModal visible={showModal} onOk={checkAuth} />
            {showPage && !showModal && (
                <>
                    <div className={'card'}>
                        <div className='prompts_block'>
                            <div className={'prompt'}>
                                <h3>
                                    <FormattedMessage id={'prompt_stage_1'} />
                                </h3>
                                <legend>System</legend>
                                <input
                                    onChange={(e) => onChangePrompt(e)}
                                    type='text'
                                    name={'system_prompt_stage_1'}
                                    defaultValue={prompts.system_prompt_stage_1}
                                />
                                <legend>User</legend>
                                <textarea
                                    onChange={(e) => onChangePrompt(e)}
                                    name='prompt_stage_1'
                                    cols='30'
                                    rows='10'
                                    defaultValue={prompts.prompt_stage_1}
                                ></textarea>
                            </div>
                            <div className={'prompt'}>
                                <h3>
                                    <FormattedMessage id={'prompt_stage_2'} />
                                </h3>
                                <legend>System</legend>
                                <input
                                    onChange={(e) => onChangePrompt(e)}
                                    type='text'
                                    name={'system_prompt_stage_2'}
                                    defaultValue={prompts.system_prompt_stage_2}
                                />
                                <legend>User</legend>
                                <textarea
                                    onChange={(e) => onChangePrompt(e)}
                                    name='prompt_stage_2'
                                    cols='30'
                                    rows='10'
                                    defaultValue={prompts.prompt_stage_2}
                                ></textarea>
                            </div>
                        </div>
                        <div className={'prompt_send'}>
                            <div>
                                <p>
                                    <FormattedMessage id={'prompt_test_ps_1'} />
                                </p>
                                <p>
                                    <FormattedMessage id={'prompt_test_ps_2'} />
                                </p>
                                <p>
                                    <FormattedMessage id={'prompt_test_ps_3'} />
                                </p>
                            </div>
                            <button onClick={onSend}>
                                <FormattedMessage id={'send'} />
                            </button>
                        </div>
                    </div>
                    <div className={'test_reports'}>
                        {testReports?.map((report) => (
                            <div className={'test_report_block'} key={report.uuid}>
                                <Report report={report} testing />
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

export default Index
